import dynamic from 'next/dynamic'
import Head from 'next/head'
import StoneleighHeader from '../../components/StoneleighHeader';
import CTA from '../../components/CTA';
import YoastSEO from '../../components/YoastSEO';
import { client } from '../../lib/apollo';
import {Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper/modules';
import { Pagination } from 'swiper/modules';
import Script from 'next/script'
import Image from "next/legacy/image";
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useRef, useCallback} from "react";
import { GET_STONELEIGH_HOME, GET_HEADER_OPTIONS, GET_FOOTER_OPTIONS } from '../../queries/queries';
import { wordpressUrl } from "../../utilities/variables";


const DynamicFooter = dynamic(() => import('../../components/StoneleighFooter'))

export default function Home({pageData}) {
  const page = pageData?.page?.pageBy?.stoneleighHome;
  const seoData = pageData?.page?.pageBy.seo;
  const news = pageData?.page?.posts;
  const events = pageData?.generalEvents;
  const volunteer = pageData?.volunteerEvents;
  const headerData = pageData?.headerData;
  const footerData = pageData?.footerData;
  const cta = pageData?.page?.pageBy?.pageCallToAction?.callToAction ? pageData?.page?.pageBy?.pageCallToAction?.callToAction[0] : null;
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const eventsSliderRef = useRef(null);

  const eventsHandlePrev = useCallback(() => {
    if (!eventsSliderRef.current) return;
    eventsSliderRef.current.swiper.slidePrev();
  }, []);

  const eventsHandleNext = useCallback(() => {
    if (!eventsSliderRef.current) return;
    eventsSliderRef.current.swiper.slideNext();
  }, []);

  const truncate = function(str) {
    return str.length > 48 ? str.substring(0, 45) + "..." : str;
  }

  return (
      <div>
        <Script defer={true}>
          {`(function (w, d, s, l, i) {
                    w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                    var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                    j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', 'GTM-KJ842L9');`}
        </Script>
        <YoastSEO
          page={seoData}
          MetaRenderElement={Head}
          meta={<>
            <meta name="MobileOptimized" content="width" />
            <meta name="HandheldFriendly" content="True"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
            <title>Natural Lands</title>
            <link rel="icon" href="/assets/favicon/favicon.ico"></link>
          </>}
        ></YoastSEO>
        <StoneleighHeader header={headerData}></StoneleighHeader>
        <main>
          <div id="content" role="main">
            <div id="stoneleigh-hero-slider" className='container-fluid'>
              {
                page.heroSlides.length > 0 && (
                  <Swiper
                    ref={sliderRef}
                    loop
                    speed={1000}
                    slidesPerView={1}
                    modules={[EffectFade]}
                    fadeEffect={ {crossFade: true} }
                    effect="fade"
                  >
                    {
                      page.heroSlides.map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className='image-wrapper'>
                                <Image
                                    src={`${item.image.sourceUrl}`}
                                    layout='fill'
                                    alt={`${item.image.altText}`}
                                />
                                {
                                  item.caption && (
                                    <div className='hero-slider-image-caption'>
                                       <a data-tooltip-id="fmv-tooltip" data-tooltip-content={`${item.photoCredit}`}>
                                          <img width={24} height={24} src="/assets/images/icon-photo-credit.svg" alt="Tooltip" />
                                      </a>
                                      <ReactTooltip openOnClick={true} id="fmv-tooltip"></ReactTooltip>
                                    </div>
                                  )
                                }
                            </div>
                            <div className='hero-image-credit'>
                                <div className='container xlarge-container'>
                                    <div>
                                        <div dangerouslySetInnerHTML={{ __html: item.caption }}></div>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                          </SwiperSlide>
                        )
                      })
                    }
                  </Swiper>
                )
              }
            </div>
            <div id="stoneleigh-hero-slider-navigation" className='container xlarge-container'>
              <div>
                <div></div>
                <div>
                  <div className="prev-arrow" onClick={handlePrev}>
                      <span className='icon-arrow'></span>
                  </div>
                  <div className="next-arrow" onClick={handleNext}>
                      <span className='icon-arrow'></span>
                  </div>
                </div>
              </div>
            </div>
            <div id="stoneleigh-home-widgets-container" className='container xlarge-container'>
              <div id="stoneleigh-home-widgets-content" className='container large-container'>
                <h1 className='hidden'>Stoneleigh: a natural garden</h1>
                <div dangerouslySetInnerHTML={{ __html: page.threeColumnWidgetContent }}></div>
              </div>
              {
                page.threeColumnWidget && (
                  <div id="stoneleigh-home-widgets">
                    {
                      page.threeColumnWidget.map((item, index) => {
                        return (
                          <div className='widget'>
                            <div className='image-wrapper'>
                              <Image
                                  src={`${item.image.sourceUrl}`}
                                  layout='fill'
                                  alt={`${item.image.altText}`}
                              />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              }
            </div>
            <div id="stoneleigh-home-tiles-container" className='container-fluid'>
              <div id="stoneleigh-home-tiles-background-image">
                <Image
                    src={`${page.visitBackgroundImage.sourceUrl}`}
                    layout='fill'
                    alt={`${page.visitBackgroundImage.altText}`}
                />
              </div>
              <div id="stoneleigh-home-tiles-content" className='container xlarge-container'>
                <div>
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: page.visitContent }}></div>
                  </div>
                  <div>
                    {
                      page.visitTiles && page.visitTiles.length > 0 && page.visitTiles.map((item, index) => {
                        return (
                          <a key={index} href={`${item.link}`} className='tile' target={`${item?.openInANewTab ? '_blank' : '_self'}`}>
                            <div className='image-wrapper'>
                              <Image
                                  src={`${item.image.sourceUrl}`}
                                  layout='fill'
                                  alt={`${item.image.altText}`}
                              />
                            </div>
                            <div className='tile-content'>
                              <h4>{item.title}</h4>
                            </div>
                          </a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              {
                page.visitBackgroundImageCaption && (
                  <div id='stoneleigh-home-tiles-background-image-caption'>
                      <a data-tooltip-id="fmv-tooltip" data-tooltip-content={`${page.visitBackgroundImageCaption}`}>
                        <img width={24} height={24} src="/assets/images/icon-photo-credit.svg" alt="Tooltip" />
                    </a>
                    <ReactTooltip openOnClick={true} id="fmv-tooltip"></ReactTooltip>
                  </div>
                )
              }
            </div>
            <div id="stoneleigh-home-events-volunteer-container" className='container'>
              <div>
                <div>
                  <div className='image-wrapper'>
                    <Image
                        src={`${page.volunteerImage.sourceUrl}`}
                        layout='fill'
                        alt={`${page.volunteerImage.altText}`}
                    />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: page.volunteerContent }}></div>
                </div>
                <div>
                  <h2>volunteer events</h2>
                  {
                    volunteer.length && (
                      volunteer.map((item, index) => {
                        return (
                          <div className='home-event-item'>
                            <a href={`${item.url}`} className='custom-link'>
                              <h4>
                                {item.title}
                              </h4>
                            </a>
                            <div className="event-list-details">
                                <p dangerouslySetInnerHTML={{__html: item.date}}></p>
                            </div>
                          </div>
                        )
                      })
                    )
                  }
                </div>
              </div>
            </div>
            <div id="stoneleigh-home-events-container" className='container-fluid'>
              <div className="container xlarge-container">
                <div id='stoneleigh-home-events-header'>
                    <div>
                        <h2>{page.upcomingEventsTitle}</h2>
                    </div>
                    <div>
                        <a href={`${wordpressUrl}/events/category/general/list/?tribe_venues%5B0%5D=722`} className='button blue-arrow'>
                            all events 
                            <span className='icon-arrow'></span>
                        </a>
                    </div>
                </div>
                {
                  events?.length > 0 && (
                    <Swiper
                      ref={eventsSliderRef}
                      modules={[Pagination]}
                      loop
                      speed={1000}
                      slidesPerView={4}
                      spaceBetween={20}
                      breakpoints={{
                        // when window width is >= 640px
                        1051: {
                          slidesPerView: 4,
                        },
                        800: {
                            slidesPerView: 3,
                        },
                        600: {
                            slidesPerView: 2,
                        },
                        0: {
                            slidesPerView: 1,
                        }
                      }}
                    >
                      {
                        events?.map((item, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <div>
                                <a href={`${item.url}`}>
                                  <div className='image-wrapper'>
                                      {
                                        item.featuredImage && (
                                          <Image
                                              src={`${item.featuredImage}`}
                                              layout='fill'
                                              alt={`${item.featuredImageAlt}`}
                                          />
                                        )
                                      }
                                  </div>
                                  <div className='event-content'>
                                      <h4>{truncate(item.title)}</h4>
                                      <div className="event-list-details">
                                          <p dangerouslySetInnerHTML={{__html: item.date}}></p>
                                      </div>
                                  </div>
                                  </a>
                              </div>
                            </SwiperSlide>
                          )
                        })
                      }
                    </Swiper>
                  )
                }
                {
                  events.length > 4 && (
                      <div id='stoneleigh-home-events-navigation'>
                          <div className="prev-arrow" onClick={eventsHandlePrev}>
                              <span className='icon-arrow'></span>
                          </div>
                          <div className="next-arrow" onClick={eventsHandleNext}>
                              <span className='icon-arrow'></span>
                          </div>
                      </div>
                    )
                }
                <div id="mobile-stoneleigh-home-events-link">
                  <a href={`${wordpressUrl}/events/category/general/list/?tribe_venues%5B0%5D=722`} className='button blue-arrow'>
                      all events 
                      <span className='icon-arrow'></span>
                  </a>
                </div>
              </div>
            </div>
            <div id="stoneleigh-home-news-container" className='container'>
              <div>
                <div>
                  <h2>latest news</h2>
                  {
                    news.edges.length && (
                      news.edges.map((item, index) => {
                        return (
                          <div className='home-news-item'>
                            <h4>
                              {item.node.title}
                            </h4>
                            <a href={`${item.node.uri}`} className='button blue-arrow'>
                              Read Post
                              <span className='icon-arrow'></span>
                            </a>
                          </div>
                        )
                      })
                    )
                  }
                </div>
                <div>
                  <div className='image-wrapper'>
                    <Image
                        src={`${page.newsImage.sourceUrl}`}
                        layout='fill'
                        alt={`${page.newsImage.altText}`}
                    />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: page.newsContent }}></div>
                </div>
              </div>
            </div>
            <CTA
              cta={cta}
            ></CTA>
          </div>
        </main>
        <DynamicFooter footer={footerData}></DynamicFooter>
      </div>
  )
}

export async function getStaticProps(params){
  const response = await client.query({
    query: GET_STONELEIGH_HOME,
    variables: {
      thisYear: new Date().getFullYear(),
      thisMonth: new Date().getMonth() + 1,
      thisDay: new Date().getDate()
    }
  });

  const page = response?.data;

  const generalEvents = await fetch(`${process.env.NEXT_PUBLIC_WORDPRESS_API_URL}/wp-json/naturallands/v1/events/?number_posts=10&category=13&venue=722`);
  const generalEventsData = await generalEvents.json();

  const volunteerEvents = await fetch(`${process.env.NEXT_PUBLIC_WORDPRESS_API_URL}/wp-json/naturallands/v1/events/?number_posts=3&category=23&venue=722`);
  const volunteerEventsData = await volunteerEvents.json();

  const headerData = await client.query({
      query: GET_HEADER_OPTIONS
  });

  const footerData = await client.query({
    query: GET_FOOTER_OPTIONS
  });
  
  return {
    props: {
      pageData: {
        page: page,
        generalEvents: generalEventsData,
        volunteerEvents: volunteerEventsData,
        footerData: footerData,
        headerData: headerData
      }
    },
    revalidate: 45,
  }
}